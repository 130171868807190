(function ($) {
  Drupal.behaviors.editorialGridFormatterV1 = {
    attach: function (context, settings) {
      var $template = $('.js-editorial-grid-formatter-v1', context);

      // Loop through and init the carousel.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $template.each(function () {
        var $self = $(this);
        var $carousel = $self.find('.js-editorial-grid-formatter__carousel');
        var $arrowsDiv = $self.find('.editorial-grid-formatter__carousel-controls');
        var $dotsDiv = $self.find('.editorial-grid-formatter__carousel-dots');
        var $dataSlidePC = $carousel.attr('data-slides-pc');
        var $dataSlideMobile = $carousel.attr('data-slides-mob');
        var slickSettings = {
          arrows: true,
          appendArrows: $arrowsDiv,
          prevArrow: '<div class="previous editorial-grid-formatter-carousel-arrow" role="button"><svg class="svgicon svgicon--arrow-16"><use xlink:href="#arrow-16"></use></svg></div>',
          nextArrow: '<div class="next editorial-grid-formatter-carousel-arrow" role="button"><svg class="svgicon svgicon--arrow-16"><use xlink:href="#arrow-16"></use></svg></div>',
          dots: true,
          appendDots: $dotsDiv,
          // prevent bug with dots not appearing active, disable infinite if scrolling more than one
          infinite: $self.data('slides-pc') && $self.data('slides-pc') > 1 ? false : true,
          slidesToShow: parseFloat($dataSlidePC) || 0,
          slidesToScroll: 1,
          rtl: site.direction.isRTL,
          autoplaySpeed: 5000,
          adaptiveHeight: false,
          pauseOnFocus: true,
          responsive: [
            {
              breakpoint: 1024, // maps to $cr19-large-up
              settings: {
                adaptiveHeight: false,
                slidesToShow: parseFloat($dataSlideMobile) || 0
              }
            }
          ]
        };
        const useSlideAlignResize = settings.slick_slider_align_resize || false;

        // Init this carousel with our settings
        $carousel.slick(slickSettings);

        // APACSIRIUS-2701: fix slick-slider card alignment issue.
        if (useSlideAlignResize) {
          let debouncedResize;
          const alignButtonPositions = () => {
            const $slideContent = $carousel.find('.js-slick-slide-card-content');
            const heights = $slideContent.get().map((el) => el.scrollHeight);
            const maxHeight = Math.max(... heights);

            $slideContent.css('height', `${maxHeight}px`);
          };

          /*
           To optimize performance and prevent the alignButtonPositions function from being called
           too frequently during window resizing debounce the resize event using a timeout, and
           cleanup when needed.
          */
          debouncedResize = _.debounce(alignButtonPositions, 250);
          $(window).on('resize', debouncedResize);
          alignButtonPositions();

          const cleanupResizeHandler = () => {
            if (debouncedResize) {
              $(window).off('resize', debouncedResize);
              debouncedResize = null;
            }
          };

          $(window).on('beforeunload', cleanupResizeHandler);
        }
      });
    }
  };
})(jQuery);
